import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import PropTypes from 'prop-types'

// custom components
import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Client from '../components/Client'
import Intro from '../components/Intro'
import LogoBar from '../components/LogoBar'
//images
import winners from '../images/web-marketing-winners.svg'

const DarkGrid = styled.div`
  background: #f7f7f7;
  padding: 2rem 0;

  padding-right: ${props => props.theme.globalPadding};
`
const ClientContainer = styled.div`
  max-width: ${props => props.theme.maxwidth};
  display: flex;
  flex-wrap: wrap;
  margin: 2rem auto 0;
`
const ClientPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Our clients"
        description="We are proud to work with amazing businesses, both big and small, around the globe."
      />
      <Header
        title="Our Clients"
        sub="We are proud to work with amazing businesses, both big and small, around the globe."
        image={winners}
      />
      <Intro>
        <h2 style={{ textAlign: 'center', marginBottom: '1rem' }}>
          We have helped these brands tell better stories
        </h2>
        <LogoBar />
      </Intro>

      <DarkGrid>
        <ClientContainer>
        <Client
            image={data.c21.childImageSharp.fluid}
            alt="Century 21 Financial"
            title="Century 21 Financial"
            sector="Real Estate/Financial Services"
            tags={[
              'Design',
              'Development',
              'GatsbyJs',
              'SEO',
            ]}
            description="Century 21 Financial has launched in New Zealand. They came to us to help them develop the fastest website in the New Zealand mortgage broking industry"
            link="https://century21financial.co.nz/"
          />
          <Client
            image={data.base.childImageSharp.fluid}
            alt="Base Backpackers"
            title="Base Backpackers"
            sector="Tourism"
            tags={[
              'On-demand Management',
              'Design',
              'Social Marketing',
              'Development',
              'GatsbyJs',
              'Adwords',
              'SEO',
            ]}
            description="Base Backpackers are one of our oldest clients. Powerboard fills the role of a senior manager, helping to guide Base's team and strategy. Most recently we redesigned their website in GatsbyJs for awesome speed results."
            link="https://www.stayatbase.com/"
          />

          <Client
            image={data.zelandez.childImageSharp.fluid}
            alt="Zelandez"
            title="Zelandez"
            sector="Mineral exploration"
            tags={[
              'Copy Writing',
              'Design',
              'Social Marketing',
              'Development',
              'Wordpress',
              'Adwords',
              'LinkedIn Ads',
              'SEO',
            ]}
            description="Zelandez is pioneering lithium exploration in South America. We help them reach an extremely targeted audience with case study marketing via Adwords and LinkedIn, as well as providing web development and general marketing services."
          />

          <Client
            image={data.top.childImageSharp.fluid}
            alt="The Opportunities Party"
            title="The Opportunities Party"
            sector="Politics"
            tags={[
              'Social Marketing',
              'Development',
              'NationBuilder',
              'Adwords',
              'LinkedIn Ads',
            ]}
            description="We led the digital marketing initiatives for TOP in the 2017 general election. Believe it or not, TOP was the third most talked about political party online in the lead up to the election with the the most positive sentiment."
          />

          <Client
            image={data.nomads.childImageSharp.fluid}
            alt="Nomads Adventure Company"
            title="Nomads Adventure Company"
            sector="Tourism"
            tags={[
              'Social Marketing',
              'Development',
              'Wordpress',
              'Adwords',
              'LinkedIn Ads',
              'Facebook Ads',
              'SEO',
            ]}
            description="We led a full redesign for Nomads and have been providing digital marketing services with a major focus on search engine optimization. "
          />
          <Client
            image={data.morgan.childImageSharp.fluid}
            alt="The Morgan Foundation"
            title="The Morgan Foundation"
            sector="Public policy research"
            tags={[
              'Copy Writing',
              'Design',
              'Social Marketing',
              'Development',
              'Wordpress',
              'Adwords',
            ]}
            description="Our main role with the Morgan Foundation was to help communicate complex public policy research and educate the public on issues of importance to all New Zealanders."
          />
          <Client
            image={data.mack.childImageSharp.fluid}
            alt="Mackerel Islands"
            title="Mackerel Islands"
            sector="Tourism"
            tags={[
              'Social Marketing',
              'Development',
              'Wordpress',
              'Adwords',
              'Facebook Ads',
              'SEO',
            ]}
            description="We do design, development and advertising for The Mackerel Islands Resort in Western Australia."
          />
        </ClientContainer>
      </DarkGrid>
    </Layout>
  )
}

export default ClientPage

ClientPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query {
    c21: file(relativePath: { eq: "c21financial.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 700) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    base: file(relativePath: { eq: "base.jpg" }) {
      name
      childImageSharp {
        fluid(maxWidth: 700) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    zelandez: file(relativePath: { eq: "zelandez.jpg" }) {
      name
      childImageSharp {
        fluid(maxWidth: 700) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    top: file(relativePath: { eq: "top.jpg" }) {
      name
      childImageSharp {
        fluid(maxWidth: 700) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    nomads: file(relativePath: { eq: "nomads.jpg" }) {
      name
      childImageSharp {
        fluid(maxWidth: 700) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    mack: file(relativePath: { eq: "mack.jpg" }) {
      name
      childImageSharp {
        fluid(maxWidth: 700) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    morgan: file(relativePath: { eq: "morgan.jpg" }) {
      name
      childImageSharp {
        fluid(maxWidth: 700) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
