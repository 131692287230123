import styled from 'styled-components'

const Intro = styled.div`
  padding: ${props => props.theme.globalPadding};
  max-width: ${props => props.theme.maxwidth};
  margin: 2rem auto 0;
  p {
    font-size: 1.1rem
  }
  /* border-bottom: 1px solid ${props => props.theme.light}; */
`

export default Intro
