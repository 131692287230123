import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';

const LogoBarWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ImgWrap = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 12%;
  padding-right: 1.5rem;
  margin: 0.5rem;
`;

function SEO({ data }) {
  return (
    <StaticQuery
      query={logoQuery}
      render={(data) => (
          <LogoBarWrapper>
            <ImgWrap>
              <Img
                fluid={data.c21Logo.childImageSharp.fluid}
                alt={data.c21Logo.name}
              />
            </ImgWrap>
            <ImgWrap>
              <Img
                fluid={data.baseLogo.childImageSharp.fluid}
                alt={data.baseLogo.name}
              />
            </ImgWrap>
            <ImgWrap>
              <Img
                fluid={data.nomadsLogo.childImageSharp.fluid}
                alt={data.nomadsLogo.name}
              />
            </ImgWrap>
            <ImgWrap>
              <Img
                fluid={data.zelandezLogo.childImageSharp.fluid}
                alt={data.zelandezLogo.name}
              />
            </ImgWrap>
            <ImgWrap>
              <Img
                fluid={data.gardenLogo.childImageSharp.fluid}
                alt={data.gardenLogo.name}
              />
            </ImgWrap>
            <ImgWrap>
              <Img
                fluid={data.topLogo.childImageSharp.fluid}
                alt={data.topLogo.name}
              />
            </ImgWrap>
            <ImgWrap>
              <Img
                fluid={data.morganLogo.childImageSharp.fluid}
                alt={data.morganLogo.name}
              />
            </ImgWrap>
            <ImgWrap>
              <Img
                fluid={data.miLogo.childImageSharp.fluid}
                alt={data.miLogo.name}
              />
            </ImgWrap>
          </LogoBarWrapper>
        )}
    />
  );
}

export default SEO;

const logoQuery = graphql`
  query logoQuery {
    c21Logo: file(relativePath: { eq: "c21logo.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 400, grayscale: true) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid
        }
      }
    }
    baseLogo: file(relativePath: { eq: "baselogo.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 400, grayscale: true) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid
        }
      }
    }
    nomadsLogo: file(relativePath: { eq: "nomadslogo.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 400, grayscale: true) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid
        }
      }
    }
    morganLogo: file(relativePath: { eq: "morganlogo.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 400, grayscale: true) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid
        }
      }
    }
    miLogo: file(relativePath: { eq: "milogo.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 400, grayscale: true) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid
        }
      }
    }
    onslowLogo: file(relativePath: { eq: "onslowlogo.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 400, grayscale: true) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid
        }
      }
    }
    gardenLogo: file(relativePath: { eq: "gardenlogo.jpg" }) {
      name
      childImageSharp {
        fluid(maxWidth: 400, grayscale: true) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid
        }
      }
    }
    topLogo: file(relativePath: { eq: "toplogo.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 400, grayscale: true) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid
        }
      }
    }
    zelandezLogo: file(relativePath: { eq: "zelandezlogo.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 400, grayscale: true) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
