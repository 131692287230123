import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import media from './Media';

const CardWrapper = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 400px;
  padding: 1rem;
  padding: ${props => props.theme.globalPadding};
  @media ${media.tablet} {
    flex-basis: 50%;
  }
  a {
    color: #333;
  }
`;
const Tags = styled.ul`
  margin: 0 0 0.8rem 0;
  padding: 0;
`;

const Tag = styled.li`
  display: inline-block;
  margin: 0 0.5rem 0.5rem 0;
  background: ${props => props.theme.light};
  border-radius: 5px;
  padding: 0.6rem;
  font-size: 0.8rem;
`;
const SubTitle = styled.h4`
  margin: 0.6rem 0;
`;

const Card = ({
  image, alt, title, description, tags, sector, link,
}) => (
  <CardWrapper>
    {link ? (
      <a href={link}>
        <h3>{title}</h3>
      </a>
    ) : (
      <h3>{title}</h3>
    )}

    <Img fluid={image} style={{ border: '1px solid #ecebff' }} alt={alt} />

    <SubTitle>Services</SubTitle>
    <Tags>
      {tags.map((tag, index) => (
        <Tag key={index}>{tag}</Tag>
      ))}
    </Tags>
    <SubTitle>Sector</SubTitle>
    <Tags>
      <Tag>{sector}</Tag>
    </Tags>

    <p>{description}</p>
  </CardWrapper>
);

export default Card;
